import {environment} from '../../../../environments/environment';
import Axios, {AxiosResponse} from 'axios';

export class WorkflowFetchCopyAxiosService {
  static create(workflowId: number, fetchId: number): Promise<AxiosResponse<void>> {
    return Axios.create().post<void>(
      `${environment.apiv4uri}workflow/${workflowId}/fetch/${fetchId}/copy`,
      {},
    );
  }
}