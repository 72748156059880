import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {BigFetchWithPositionWithUse, FetchPosition, FetchPositionUpdateForm} from '../../models/fetch.models';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {debug} from '../../../../helper/debug.func';
import {FetchService} from '../../workflow-services/fetch.service';
import {AlertService} from '../../../../common/alert-service/alert.service';

@Component({
  selector: 'tr[delivery-fetch-item-row]',
  templateUrl: './delivery-create-form-fetch-item.component.html',
})
export class DeliveryCreateFormFetchItemComponent implements OnInit, OnDestroy {
  // we need to disable ts-lint since we want to rename the input properties
  @Input('delivery-fetch-item-row-newNr') newNr: boolean;
  @Input('delivery-fetch-item-row') fetch: BigFetchWithPositionWithUse;
  @Input('delivery-fetch-item-row-workflow-id') workflowId: number;
  @Output('delivery-fetch-item-row-reload-list') reloadList: EventEmitter<void> = new EventEmitter<void>();
  form: UntypedFormGroup;
  private formSubscription?: Subscription;

  constructor(private fb: UntypedFormBuilder,
              private fetchService: FetchService,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      'quantity': [this.fetch.positions[0].quanity]
    });

    this.form.get('quantity').valueChanges.pipe(debounceTime(1500)).subscribe(formValue => {
      debug('Quantity Changed:', this.fetch.id, formValue);
      this.updateFetch(formValue);
    });
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  toggle() {
    this.fetch.use = !this.fetch.use;
  }

  private updateFetch(formValue: any): void {
    const data: FetchPositionUpdateForm = {
      date: this.fetch.delivery ? this.fetch.delivery : null,
      label: this.fetch.positions[0].label,
      quanity: formValue,
      price: this.fetch.positions[0].price,
      order_nr: this.fetch.order_nr,
      is_complaint: this.fetch.is_complaint,
      earliest_delivery: this.fetch.earliest_delivery,
    };
    this.fetchService.update(this.fetch.positions[0].id, data).subscribe(response => {
      if (!!response['object']) {
        this.fetch.positions[0] = response['object'];
        this.form.patchValue({'quantity': response['object'].quanity}, {emitEvent: false});
      } else if (!!response['objects']) {
        this.fetch.positions = response['objects'];
      }
      this.alertService.add('success', 'Abruf Position erfolgreich gespeichert!');
    }, () => {
      this.alertService.add('danger', 'Konnte Abruf Position nicht speichern');
    });
  }

}
