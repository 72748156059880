<form [formGroup]="form">
  <div class="form-group row">
    <label class="control-label col-md-4">Lieferadresse: *</label>

    <div class="col-md-8">
      <address-box [customerId]="data.customer.id"
                   [customerChangeNoWriteBack]="false"
                   [deliveryId]="address.id"
                   [realValue]="true"
                   [writeBack]="false"
                   [button]="true"
                   formControlName="address"
                   [ngClass]="{red_alert: errors['obj.address_id']}">
      </address-box>
    </div>
  </div>

  <div class="form-group row">
    <label for="date" class="control-label col-md-4">Lieferschein-Datum: *</label>

    <div class="col-md-8">
      <ev-datepicker id="date" class="form-control"
                     formControlName="date"
                     [evClass]="{red_alert: errors['obj.date']}">
      </ev-datepicker>
    </div>
  </div>

  <div class="form-group row">
    <label for="shipping" class="control-label col-md-4">Anzahl Versand Etiketten: *</label>

    <div class="col-md-8">
      <integer-input id="shipping" formControlName="labelCount" class="form-control"
                     [inputSelect]="true"
                     [changeEvent]="false">
      </integer-input>
    </div>
  </div>


  <div class="form-group row">
    <label for="new" class="control-label col-md-4">Neue Lieferschein-Nr.:</label>

    <div class="col-md-8">
      <input id="new" type="checkbox" class="form-control" style="width: 30px;margin-left: -4px;" formControlName="nr">
    </div>
  </div>

  <div class="form-group row">
    <label for="hint" class="control-label col-md-4">Hinweis</label>

    <div class="col-md-8">
      <textarea id="hint" class="form-control" formControlName="hint"></textarea>
    </div>
  </div>
</form>

<form>
  <table class="table whitetable">
    <thead>
    <tr>
      <th>Abruf</th>
      <th>Menge</th>
      <th>Lieferdatum</th>
      <th colspan="2">&nbsp;</th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let fetch of fetchList; trackBy: trackByFetchList; let index = index"
        [ngClass]="{'selectedgray': !fetch.use}"
        [delivery-fetch-item-row]="fetch"
        [delivery-fetch-item-row-workflow-id]="data.workflow.object.id"
        [delivery-fetch-item-row-newNr]="newNr"
        (delivery-fetch-item-row-reload-list)="reloadList()">
    </tr>
    </tbody>
  </table>
</form>

<div class="row">
  <div class="col-md-12" *ngIf="!!data.article.locked">
    <div class="alert alert-danger">
      <span class="bold">Artikel wurde gesperrt!</span>&nbsp;
      <ng-container *ngIf="data.workflow.frame.typ === 'de'">Lieferschein Erstellung nicht möglich!</ng-container>
    </div>
  </div>
  <div class="col-md-12">
    <button type="button" class="btn btn-success pull-right" (click)="create()" [disabled]="isDisabled()">
      Lieferschein erstellen
    </button>
  </div>
</div>
