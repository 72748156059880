<div ngbDropdown placement="bottom" id="settings" class="d-inline-block btn-group">
  <button type="button" ngbDropdownToggle class="btn btn-default dropdown-toggle" id="dropdown-settings"
          data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
    <span class="fa fa-align-justify"></span>
  </button>

  <div ngbDropdownMenu>
    <a uiSref="analysis.fetch.overview" [uiParams]="{start: year, end: year}" *ngIf="'analysis'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-line-chart"></i>
        </div>
        <div class="icon-label">
          Auswertungen
        </div>
      </button>
    </a>

    <a uiSref="analysis.executive.liquidity" *ngIf="'analysis.executive'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-line-chart green"></i>
        </div>
        <div class="icon-label">
          GF Auswertungen
        </div>
      </button>
    </a>

    <a uiSref="analysis_business_order.step" [uiParams]="{start: year, end: year}"
       *ngIf="'business_order.analysis'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-line-chart"></i>
        </div>
        <div class="icon-label">
          BA Auswertungen
        </div>
      </button>
    </a>

    <a uiSref="analysis_material.inventory" *ngIf="'material.analysis'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-line-chart"></i>
        </div>
        <div class="icon-label">
          Material Auswertungen
        </div>
      </button>
    </a>

    <a uiSref="a.workload.timetable" *ngIf="'workload.timetable'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-line-chart"></i>
        </div>
        <div class="icon-label">
          Auslastungskalender
        </div>
      </button>
    </a>

    <a uiSref="a.workflow.i.invoice" *ngIf="'invoice.automation'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-eur" aria-hidden="true"></i>
        </div>
        <div class="icon-label">
          Rechnungslauf
        </div>
      </button>
    </a>

    <a uiSref="a.dunning.automation" *ngIf="'dunning'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </div>
        <div class="icon-label">
          Mahnungslauf
        </div>
      </button>
    </a>

    <a uiSref="a.calculation.list" *ngIf="'settings.calculation'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-table"></i>
        </div>
        <div class="icon-label">
          Kalkulationstabellen
        </div>
      </button>
    </a>

    <article-config-redirect type="dropdown"></article-config-redirect>

    <a uiSref="a.holiday.manage" *ngIf="'article.extra'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-calendar"></i>
        </div>
        <div class="icon-label">
          Feiertage
        </div>
      </button>
    </a>

    <a uiSref="a.material.article.list" *ngIf="'material.settings'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-inbox"></i>
        </div>
        <div class="icon-label">
          Materialverwaltung
        </div>
      </button>
    </a>

    <a uiSref="a.user.list" *ngIf="'user.change'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-user"></i>
        </div>
        <div class="icon-label">
          Benutzerverwaltung
        </div>
      </button>
    </a>

    <a uiSref="a.label.settings" *ngIf="'label.settings'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-print"></i>
        </div>
        <div class="icon-label">
          Etiketteneinstellungen
        </div>
      </button>
    </a>


    <a uiSref="a.config.main" *ngIf="'settings.settings'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
         <span class="fa fa-stack subscript right">
           <i class="fa fa-cog fa-stack-1x dimgray"></i>
           <i class="fa fa-cog fa-stack-2x"></i>
         </span>
        </div>
        <div class="icon-label">
          System Einstellungen
        </div>
      </button>
    </a>

    <a uiSref="a.config.price_increase_flex" *ngIf="'settings.settings'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
         <span class="fa fa-stack subscript right">
           <i class="fa fa-cog fa-stack-1x dimgray"></i>
           <i class="fa fa-euro fa-stack-2x"></i>
         </span>
        </div>
        <div class="icon-label">
          Kosten Einstellungen
        </div>
      </button>
    </a>

    <a uiSref="a.config.customer" *ngIf="(
                 ('field_service.config'|HasPerm) ||
                 ('settings.customer.industrial_sectors'|HasPerm) ||
                 ('settings.customer.departments'|HasPerm) ||
                 ('task.offer.rejection.reason.change'|HasPerm) ||
                 ('price.increase.global'|HasPerm))">
      <button type="button" class="dropdown-item">
        <div class="icon">
         <span class="fa fa-stack subscript right">
           <i class="fa fa-cog fa-stack-1x dimgray"></i>
           <i class="fa fa-user fa-stack-2x"></i>
         </span>
        </div>
        <div class="icon-label">
          Kunden Einstellungen
        </div>
      </button>
    </a>

    <a uiSref="a.config.pdf_text" *ngIf="'settings.settings'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
         <span class="fa fa-stack subscript right">
           <i class="fa fa-file-o fa-stack-1x dimgray"></i>
           <i class="fa fa-user fa-stack-2x"></i>
         </span>
        </div>
        <div class="icon-label">
          Kunden Dokumente
        </div>
      </button>
    </a>

    <a uiSref="a.dashboard.main" [uiParams]="{dashboard: true}" *ngIf="'ba.list'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-tasks"></i>
        </div>
        <div class="icon-label">
          Dashboard
        </div>
      </button>
    </a>

    <a href="#!/dashboard/plotting" *ngIf="('plotting.list'|HasPerm)">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <span class="fa fa-stack subscript right">
            <i class="fa fa-file-picture-o fa-stack-1x dimgray"></i>
            <i class="fa fa-tasks fa-stack-2x"></i>
          </span>
        </div>
        <div class="icon-label">
          Plotting Dashboard
        </div>
      </button>
    </a>

    <a uiSref="a.export" *ngIf="(('export.customer.contact'|HasPerm) || ('business_order.analysis'|HasPerm))">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-download"></i>
        </div>
        <div class="icon-label">
          Export
        </div>
      </button>
    </a>

    <a uiSref="a.cluster.info" *ngIf="'cluster.administration.info'|HasPerm">
      <button type="button" class="dropdown-item">
        <div class="icon">
          <i class="fa fa-server"></i>
        </div>
        <div class="icon-label">
          Cluster Informationen
        </div>
      </button>
    </a>

  </div>

  <a (click)="logout()" title="Abmelden" class="btn btn-default">
    <span class="fa fa-sign-out"></span>
  </a>
</div>
