import * as React from 'react';
import {Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild} from '@angular/core';
import {ReactBaseComponent} from '../../../../react/base/react-base.component';
import {WorkflowFetchCopyCreate} from '../../../../react/workflow/fetch/workflow-fetch-copy-create';

const containerElementName = 'reactDeliveryCreateFetchSplitModalContainer';

@Component({
  selector: 'delivery-fetch-copy-create',
  template: `<div #${containerElementName}></div>`,
})
export class DeliveryFetchCopyCreateComponent extends ReactBaseComponent {
  @ViewChild(containerElementName, {static: false}) containerRef: ElementRef;
  @Input() workflowId: number;
  @Input() fetchId: number;
  @Output() reloadList: EventEmitter<void> = new EventEmitter<void>();

  constructor(private ngZone: NgZone) {
    super();
  }

  reload(): void {
    this.ngZone.run(() => {
      this.reloadList.emit();
    });
  }

  protected override renderComponent() {
    const _ = React.version;
    return <WorkflowFetchCopyCreate workflowId={this.workflowId}
                                    fetchId={this.fetchId}
                                    reload={() => this.reload()}/>;
  }
}
