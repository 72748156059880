import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Injectable} from '@angular/core';
import {DeliveryCreateMainErrorModalComponent} from './delivery-create-main-error-modal.component';
import {from} from 'rxjs';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {DeliveryCreateLowInventoryErrorModalComponent} from './delivery-create-low-inventory-error-modal.component';
import {DeliveryCreateWeightsInvalidErrorModalComponent} from './delivery-create-weights-invalid-error-modal.component';
import {DeliveryCreateMessageModalComponent} from './delivery-create-message-modal.component';

@Injectable({providedIn: 'root'})
export class DeliveryModalService {

  constructor(private modalService: NgbModal) {
  }

  openMessageModal(message: string): void {
    const modalRef = this.modalService.open(DeliveryCreateMessageModalComponent, {windowClass: 'modal2-mm'});
    modalRef.componentInstance.message = message;
    from(modalRef.result).subscribe(ignoreRejection, ignoreRejection);
  }

  openErrorModal(errorType: string): void {
    if (errorType === 'inventory_low') {
      this.openLowInventoryError();
    } else if (errorType === 'weights are invalid') {
      this.openInvalidWeightsError();
    } else if (errorType === 'CustomerSingleDeliveryPositionEnforced') {
      this.openMainError('Der Kunde wünscht eine einzelne Lieferposition per Lieferschein. Bitte Ausbuchungen und Abrufe prüfen!');
    } else {
      this.openMainError();
    }
  }

  private openMainError(msg?: string): void {
    const modalRef = this.modalService.open(DeliveryCreateMainErrorModalComponent, {windowClass: 'modal2-mm'});
    modalRef.componentInstance.error = msg ?? null;
    from(modalRef.result).subscribe(ignoreRejection, ignoreRejection);
  }

  private openLowInventoryError(): void {
    const modalRef = this.modalService.open(DeliveryCreateLowInventoryErrorModalComponent, {windowClass: 'modal2-mm'});
    from(modalRef.result).subscribe(ignoreRejection, ignoreRejection);
  }

  private openInvalidWeightsError(): void {
    const modalRef = this.modalService.open(DeliveryCreateWeightsInvalidErrorModalComponent, {windowClass: 'modal2-mm'});
    from(modalRef.result).subscribe(ignoreRejection, ignoreRejection);
  }

}
