import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {BigCreateDeliveryWorkflow} from '../../models/workflow-create.models';
import {BigFetchWithPosition, BigFetchWithPositionWithUse} from '../../models/fetch.models';
import {noop} from '../../../../helper/noop';
import {debug} from '../../../../helper/debug.func';
import {debounceTime, map, mergeMap} from 'rxjs/operators';
import {Address} from '../../../customer/model/address';
import {DeliveryCreateModalService} from '../create-modal/delivery-create-modal.service';
import {DeliveryCreateModalData} from '../create-modal/delivery-create-modal.models';
import {DeliveryModalService} from '../modals/delivery-modal.service';
import {EnvisiaFileService} from '../../../../common/envisia-file-button/envisia-file.service';
import {StateService} from '@uirouter/core';
import {ignoreRejection} from '../../../../helper/ignore_rejection';
import {Subscription} from 'rxjs';
import {WorkflowService} from '../../workflow-services/workflow.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {FrameService} from '../../workflow-services/frame.service';
import {MergedFrame} from '../../models/frame.models';


@Component({
  selector: 'delivery-create-form',
  templateUrl: './delivery-create-form.component.html',
})
export class DeliveryCreateFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: BigCreateDeliveryWorkflow;
  @Input() frame: MergedFrame;
  address: Address;
  newNr = true;
  fetchList: BigFetchWithPositionWithUse[];
  form: UntypedFormGroup;
  errors: { [key: string]: any; } = {};
  formHintSubscription?: Subscription;
  formAddressSubscription?: Subscription;
  formNrSubscription?: Subscription;


  constructor(private fb: UntypedFormBuilder,
              private stateService: StateService,
              private deliveryCreateModalService: DeliveryCreateModalService,
              private deliveryModalService: DeliveryModalService,
              private envisiaFileService: EnvisiaFileService,
              private workflowService: WorkflowService,
              private alertService: AlertService,
              private frameService: FrameService) {
  }

  ngOnInit() {
    // check if the latest delivery is a storno
    this.address = this.data.addresses.find(val => val.id === this.data.workflow.object.delivery_id);
    this.form = this.fb.group({
      'address': [this.address],
      'nr': [true],
      'date': [new Date(), Validators.required],
      'hint': [this.data.additional ? this.data.additional.delivery_hint : null],
      'labelCount': [0, Validators.compose([Validators.required, Validators.min(0)])],
    });
    this.init();

    this.formNrSubscription = this.form.get('nr').valueChanges.subscribe(value => {
      this.newNr = value;
    });
    this.formAddressSubscription = this.form.get('address').valueChanges.pipe(
      mergeMap(value => {
        return this.workflowService.update(this.data.workflow.object.id, {delivery_id: value.id}).pipe(
          map(() => value)
        );
      })
    ).subscribe(value => {
      debug('address Form Changed:', value);
      this.alertService.add('success', 'Addresse erfolgreich geändert!');
      this.address = value;
    }, (error) => {
      this.form.patchValue({'address': this.address}, {emitEvent: false});
      this.alertService.add('danger', 'Addresse konnte nicht geändert werden!');
      debug('address Form Failed:', error);
    });

    this.formHintSubscription = this.form.get('hint').valueChanges.pipe(debounceTime(350)).subscribe(value => {
      this.frameService.additionalSave(this.data.workflow.object.id, {delivery_hint: value}).subscribe(() => {
        this.alertService.add('success', 'Hinweis erfolgreich geändert!');
      }, () => {
        this.alertService.add('danger', 'Hinweis konnte nicht geändert werden!');
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.frame && !changes.frame.isFirstChange()) {
      this.init();
    }
  }


  ngOnDestroy(): void {
    if (this.formHintSubscription) {
      this.formHintSubscription.unsubscribe();
    }
    if (this.formAddressSubscription) {
      this.formAddressSubscription.unsubscribe();
    }
    if (this.formNrSubscription) {
      this.formNrSubscription.unsubscribe();
    }
  }

  hasInvoiceOrStornoOrDeliveryListZero(): boolean {
    const deliveryListNonZero = this.data.delivery.objects.length > 0;
    const firstDelivery = deliveryListNonZero ? this.data.delivery.objects[0] : null;
    const firstDeliveryId = firstDelivery ? firstDelivery.delivery_invoice_id : null;
    const hasStorno = deliveryListNonZero && !!firstDelivery.storno;
    const hasInvoice = this.fetchList.some(val =>
      val.invoice_obj && val.status === 5 && val.invoice_obj.id === firstDeliveryId);
    return hasStorno || hasInvoice || !deliveryListNonZero;
  }

  create(): void {
    const nr = this.hasInvoiceOrStornoOrDeliveryListZero() ? true : this.form.value.nr;
    const send: DeliveryCreateModalData = {
      nr: nr,
      delivery_date: this.form.value.date,
      hint: this.form.value.hint,
      fetch: this.fetchList.filter(val => val.use && val.status >= 1).map(val => val.id),
    };

    debug('Create Values:', this.form.value, 'Send Values', send);
    const workflow = this.data.workflow.object;
    const labelCount = this.form.value.labelCount;
    const germanDelivery = this.form.value.address.country === 'DEU';

    this.deliveryCreateModalService.open(workflow, send, labelCount, germanDelivery).subscribe(response => {
      this.envisiaFileService.open(response.object.id, 'delivery', true);

      if (response.message !== 'Empty') {
        this.deliveryModalService.openMessageModal(response.message);
      }

      this.stateService.go('a.workflow.detail', {id: workflow.id});
    }, ignoreRejection);
  }

  isDisabled() {
    const ret = this.fetchList.some((data) => data.use);
    return !ret || (!!this.data.article.locked && this.data.workflow.frame.typ === 'de') || !this.form.valid;
  }

  trackByFetchList(index: number, item: BigFetchWithPositionWithUse) {
    noop(this, index);
    return item.id;
  }

  private init(): void {
    this.initFetchList(this.frame.fetch);
    this.nrEnabler();
  }

  private nrEnabler(): void {
    if (this.form) {
      const disabledNr = this.hasInvoiceOrStornoOrDeliveryListZero();
      disabledNr ? this.form.get('nr').disable() : this.form.get('nr').enable();
    }
  }

  private initFetchList(fetch: BigFetchWithPosition[]): void {
    this.fetchList = fetch
      .filter(val => val.status !== 0)
      .map(val => {
        const fetch = val as BigFetchWithPositionWithUse;
        fetch.use = fetch.status > 0 && fetch.status < 4;
        return fetch;
      });
  }

  reloadList(): void {
    this.frameService.get(this.frame.workflow_id).subscribe(f => {
      this.initFetchList(f.frame.fetch.sort((a, b) => a.ordering - b.ordering));
    }, () => {});
  }

}
