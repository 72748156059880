<ng-container [formGroup]="form">
  <td>
    {{ fetch.ordering }} (#{{fetch.id}})
    <span *ngIf="fetch.status === 1" class="label label-primary ng-scope">BESTÄTIGT</span>
    <span *ngIf="fetch.status === 3" class="label label-rose ng-scope">PRODUZIERT</span>
    <span *ngIf="fetch.status === 4" class="label label-warning ng-scope">GELIEFERT</span>
    <span *ngIf="fetch.status === 5" class="label label-success ng-scope">ABGERECHNET</span>
    <span *ngIf="!fetch.contract_obj && fetch.status <= 4" class="label label-danger ng-scope">AB FEHLT</span>
  </td>
  <td>
    <ng-container *ngIf="fetch.status === 5">{{ fetch.positions[0].quanity }}</ng-container>
    <ng-container *ngIf="fetch.status !== 5">
      <integer-input formControlName="quantity" [inputSelect]="true" [changeEvent]="false"></integer-input>
    </ng-container>
  </td>
  <td>{{ fetch.delivery }}</td>
  <td>
    <ng-container *ngIf="(fetch.status < 4) && !fetch.blocked;">
      <delivery-fetch-copy-create [workflowId]="workflowId"
                                  [fetchId]="fetch.id"
                                  (reloadList)="reloadList.emit()">
      </delivery-fetch-copy-create>
    </ng-container>
  </td>
  <td>
    <ng-container *ngIf="(fetch.status < 4 || (fetch.status === 4 && !newNr)) && !fetch.blocked;">
      <button class="btn btn-xs w-[75px]"
              [ngClass]="{'btn-danger': fetch.use, 'btn-success': !fetch.use}"
              [title]="(fetch.use ? 'Abruf aus Lieferschein entfernen' : 'Abruf zum Lieferschein hinzufügen')"
              (click)="toggle()">
        {{fetch.use ? 'Entfernen' : 'Auswählen'}}
      </button>
    </ng-container>
  </td>
</ng-container>
