import * as React from 'react';
import {WorkflowFetchCopyAxiosService} from './workflow-fetch-copy-axios.service';
import {useState} from 'react';
import {EvReactModal} from '../../modal/EvReactModal';
import {EvConfirm} from '../../modal/EvConfirm';

interface Props {
  workflowId: number;
  fetchId: number;
  reload?: () => void;
}

export function WorkflowFetchCopyCreate(props: Props): JSX.Element {
  const [state, setState] =
    useState<{ modalState: 'closed' | 'open', interactionLocked: boolean }>({
      modalState: 'closed',
      interactionLocked: false,
    });
  const copyFetch: () => void = async () => {
    if (state.interactionLocked) {
      return;
    }

    setState({...state, interactionLocked: true});
    try {
      const response = await WorkflowFetchCopyAxiosService.create(props.workflowId, props.fetchId);
      if (response.status === 204) {
        if (props.reload) {
          setState({modalState: 'closed', interactionLocked: false});
          props.reload();
        } else {
          setState({...state, interactionLocked: false});
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toggleModal: (modalState: 'open' | 'closed') => void = (modalState) => {
    setState({...state, modalState: modalState});
  };

  return <>
    <button className="btn btn-info btn-xs w-[75px]"
            title="Den Abruf und alle (nicht einmaligen) Abruf Positionen kopieren"
            disabled={state.interactionLocked}
            onClick={() => setState({...state, modalState: 'open'})}>
      Kopieren
    </button>
    <EvReactModal contentLabel="Abruf kopieren"
                  showModal={state.modalState === 'open'}
                  modalClosed={() => setState({...state, modalState: 'closed'})}>
      <EvConfirm response={confirmed => confirmed ? copyFetch() : toggleModal('closed')}>
        Soll der Abruf und alle dazugehörigen, nicht einmaligen Abruf Positionen wirklich kopiert werden?
      </EvConfirm>
    </EvReactModal>
  </>;
}
