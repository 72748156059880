import {Component, Input, OnInit} from '@angular/core';
import {
  MaterialPlanningDate,
  MaterialPlanningListElement,
  MaterialPlanningListQuery,
  MaterialPlanningRelation,
  MaterialSelect,
} from '../../../material/planning/material-planning.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialPlanningService} from '../../../material/planning/material-planning.service';
import {MaterialGroupListElement} from '../../../material/material-management.model';
import {ArticleBaseMaterialFormWithKey} from '../../../article/models/article-specification.model';
import {Lister} from '../../../../common/wrapper.models';
import {ArticleMultilayerPlan} from '../../../article/main/multilayer-plan/article-multilayer-plan.models';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {HttpErrorResponse} from '@angular/common/http';
import {BusinessOrderCreateDirective} from '../create/business-order-create.directive';
import {Article} from '../../../article/models/article.models';

@Component({
  selector: 'business-order-material-select-modal',
  templateUrl: './business-order-material-select-modal.component.html',
  styleUrls: ['../../../material/planning/material-planning.styles.scss'],
})
export class BusinessOrderMaterialSelectModalComponent
  extends BusinessOrderCreateDirective
  implements OnInit {
  @Input() selectionData: MaterialSelect;
  @Input() groups: Lister<MaterialGroupListElement>;
  @Input() baseMaterial: ArticleBaseMaterialFormWithKey[];
  @Input() multilayerPlan: ArticleMultilayerPlan | null;
  article: Article;

  isMl: boolean;
  suggestedTgValue: string;
  currentGroupId?: number = null;

  currentPreferredMaterial: MaterialPlanningRelation[] = [];
  params: MaterialPlanningListQuery;

  selectedWeek: MaterialPlanningDate | null = null;
  selectedVariationId: number | null = null;

  usedFields: { id: number, value: any }[];

  lockSelection = false;

  hasCustomerSpecificationMaterial = false;

  constructor(private modalRef: NgbActiveModal,
              private service: MaterialPlanningService,
              private alertService: AlertService) {
    super();
  }

  ngOnInit(): void {
    this.article = this.selectionData.article;
    this.initMultilayerPlanCheck();
    const execution: string = this.selectionData.business_order.article_data.execution;
    this.hasCustomerSpecificationMaterial = (
      !!this.selectionData.article.data.customer_specification_material &&
      !!this.selectionData.article.data.customer_specification_material_type
    );
    this.isMl = execution && execution.startsWith('ML');
    const bmArticle =
      this.baseMaterial.find(bm => bm.key === this.selectionData.article.data.material_internal) ??
      this.baseMaterial.find(bm => bm.key === this.selectionData.article.data.manufacturer);
    if (bmArticle?.data?.tg === null || bmArticle?.data?.tg === undefined) {
      this.suggestedTgValue = '- C°';
    } else if (bmArticle.data.tg < 150) {
      this.suggestedTgValue = '140 C°';
    } else if (bmArticle.data.tg < 170) {
      this.suggestedTgValue = '150 C°';
    } else {
      this.suggestedTgValue = '170 C°';
    }
    const baseMaterial = this.selectionData.planning.filter(x => x.component === 1);
    const rigidSelected = baseMaterial.find(x => (x.selected || x.conflict) && x.inventory?.group_id === 2);
    const mlSelected = baseMaterial.find(x => (x.selected || x.conflict) && x.inventory?.group_id === 1);
    this.currentGroupId = (!rigidSelected && this.isMl) || mlSelected ? 1 : 2;
    this.refreshCurrentSelectedMaterial();
  }

  selectMaterial(material: MaterialPlanningListElement): void {
    this.lockSelection = true;
    const id = this.selectionData.business_order.id;
    const index = this.selectionData.business_order.index;
    const isPrepreg = this.currentGroupId === 3;
    this.service.setMaterial(
      id,
      index,
      isPrepreg ? 'prepreg' : 'base',
      isPrepreg ?
        {
          manufacturer: material.article_name,
          tg_value: material.variation?.article?.article_fields?.find(v => v.id == 41)?.value as string,
        } : {
          variation_id: material.id
        },
    ).subscribe(response => {
      this.modalRef.close(response);
    }, (error: HttpErrorResponse) => {
      console.error(
        `Error while trying to reserve material on BA${id}-${index} for Group with id ${this.currentGroupId}`,
        error,
      );
      this.alertService.add('danger', 'Konnte material nicht reservieren');
    });
  }

  setGroup(id: number): void {
    this.currentGroupId = id;
    this.selectedWeek = null;
    this.selectedVariationId = null;
    this.refreshCurrentSelectedMaterial();
  }

  refreshCurrentSelectedMaterial(): any {
    this.currentPreferredMaterial = (
      this.selectionData?.planning?.filter(m => m.linked_material_variation?.article?.group_id === this.currentGroupId)
      ?? []);
    const params: MaterialPlanningListQuery = {group_id: this.currentGroupId};
    const selected = this.currentPreferredMaterial.find(m => m.selected || m.selected_manual);
    if (!!selected && selected.linked_material_variation) {
      const selectedVariation = selected.linked_material_variation;
      params.supplier_id = selectedVariation.article.supplier_id;
      params.article_name = selectedVariation.article.name;
      this.usedFields = [...selectedVariation.variation_fields, ...selectedVariation.article.article_fields];
    } else {
      this.usedFields = this.selectionData.fields;
    }

    const usedFieldIds = this.getUsedFieldIds();
    this.usedFields.filter(f => !!usedFieldIds.find(q => q === f.id)).forEach(f => {
      params[`field_${f.id}`] = f.value?.toString();
    });

    this.params = params;
  }

  getUsedFieldIds(): number[] {
    switch (this.currentGroupId) {
      case 1:
        return [2, 3, 41];
      case 2:
        return [1, 4, 5, 41];
      case 3: // for prepreg only the name of the material is used, as there can be multiple variations of the same material selected at once
        return [];
      default:
        return [];
    }
  }

  setSelectedWeek(data: {week: MaterialPlanningDate, variationId: number} | null) {
    this.selectedWeek = data.week;
    this.selectedVariationId = data.variationId;
  }
}
